import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const websiteDataAll = require("./website-location-count.json");
const websiteData =
  websiteDataAll?.pageProps?.iniVehicleFilterOptions?.filters?.dealership || [];
const ag_to_eclipse_mapping = require("../../assets/json/ag_to_eclipse_mapping.json");
console.log("websiteData", websiteData);

const AutogateOnlineReportBySiteComponent = ({ onlineData }) => {
  const [show, setShow] = useState(false);
  const [websiteCount, setWebsiteCount] = useState({});

  // const [websiteData, setWebsiteData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getWebsiteCountByAgName = (sa) => {
    console.log("sa", sa[0]);
    const agSiteId = sa[0]?.Seller?.Identifier;
    console.log("agSiteId", agSiteId);
    const agMapped = ag_to_eclipse_mapping.find((m) => {
      return m?.guid === agSiteId;
    });
    console.log("agMapped", agMapped);
    if (!agMapped) {
      return "";
    }

    let agName = agMapped?.autogate_name;
    if (agSiteId === "844e6591-dc47-533f-215d-702efa124fb9") {
      agName = "Dutton One Westgate";
    }
    console.log("agName", agName);

    try {
      const foundData = websiteData.find((w) => {
        console.log("w", w?.data?.name);
        return w?.data?.name.trim() === agName.trim();
      });
      if (agName === "Dutton One Westgate") {
        // Add Imports count
        const importsCount = websiteData.find((w) => {
          return w?.data?.name === "Dutton One Japanese Import Vehicles";
        });
        console.log("importsCount", importsCount);
        return foundData?.vCount + importsCount?.vCount;
      }
      console.log("foundData", foundData);
      return foundData?.vCount || 0;
    } catch (e) {
      console.log("error", e);
      return "";
    }
  };

  const getWebsiteData = async () => {};

  useEffect(() => {
    getWebsiteData();
  }, []);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Show Autogate Online Report By Site
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Autogate Online Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>State</th>
                <th>Site</th>
                <th>Carsales</th>
                <th>Website</th>
                <th>Diff</th>
              </tr>
            </thead>
            <tbody>
              {onlineData?.map((state) => {
                return state?.siteArray
                  ?.filter((sa) => {
                    return sa.Site?.startsWith("D1");
                  })
                  ?.map((sa, idx) => {
                    return (
                      <tr key={`${sa.Site}_${idx}`}>
                        <td>{state.State || ""}</td>
                        <td>{sa.Site || ""}</td>
                        <td>{Object.keys(sa)?.length - 1}</td>
                        {/* <td>{getWebsiteCountByAgName(sa)}</td> */}
                        <td>TBC</td>
                        {/* <td>
                          {Object.keys(sa)?.length -
                            1 -
                            getWebsiteCountByAgName(sa)}
                        </td> */}
                        <td>TBC</td>
                      </tr>
                    );
                  });
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AutogateOnlineReportBySiteComponent;
